import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/weltel-hero.jpg';
import TwoCol from '../components/rows/twocol/index';

import AccordionTitle from '../components/elements/accordion/accordionTitle';
import Accordion from '../components/elements/accordion';
import CtaFluid from '../components/rows/cta/ctaFluid';

import Follow from '../components/rows/cta/follow';

import TwoColImage from '../images/weltel/phone-mockup.jpg';
import QRCode from '../images/weltel/weltel-qr-code.svg';
import WelTelImage from '../images/weltel/landing-img-2.jpg';

const content = {
  cardiac_two_col: {
    title: 'A secure texting service that supports your care',
    description:
      "Communication is key! That's why we've launched WelTel to support your care. WelTel is a secure messaging tool that lets us check in with you regularly and helps avoid busy phone lines. You'll receive a text from us, and you can reply with any concerns or questions about your cardiac care. \n\nYou can also text us directly whenever you need support, so we can stay connected and help you between visits. **For medical emergencies, please call 911 or visit your local emergency department**.",
    image: TwoColImage,
  },
  cta_fluid: {
    title: 'Enrollment is open to existing PACE patients only',
    sub_title:
      "If you haven't enrolled in our text service yet, scan the QR code or click below to sign up. Messages are only responded to during business hours (Monday to Friday 8 am to 4:30 pm).  Note: If you've received a text from PACE Cardiology starting with \"Hi! You are registered for non-emergency texting...\" you're already enrolled.",
    btn_name: 'Enroll with WelTel',
    btn_link_to:
      'https://ca-on-cardiology-pace1.weltelhealth.net/enroll?clinicId=32',
    image: QRCode,
  },
  accordion_title: { title: 'Frequently Asked Questions' },
  cardiac_img_text: {
    title: 'The main benefits of WelTel include:',
    description:
      '- Improved communication.\n- Improved support and follow-up with your healthcare team.\n- A secure platform where you can easily check in, ask questions, and receive reminders for appointments or treatments.\n- Increased accessibility to help you feel more connected, while addressing your health concerns promptly.',
    button_title: 'Visit WelTel Website',
    button_link:
      'https://ca-on-cardiology-pace1.weltelhealth.net/enroll?clinicId=32',
    image: WelTelImage,
  },
  faq_acc: [
    {
      title: 'What is WelTel?',
      description:
        'WelTel is a secure and discreet way to communicate with your known healthcare providers using SMS and voice/video when needed.',
    },
    {
      title: 'What Can You Use WelTel for?',
      description:
        'You can use WelTel for various purposes, such as:\n\n- Appointment Reminders: Checking appointment dates, times, or locations.\n- Parking information\n- Symptom Updates: Reporting new or worsening symptoms for timely advice.\n- Post-Treatment Follow-Up: Asking questions about recovery after a procedure.\n- Test Results: Inquiring about pending lab or imaging results.\n- Preparation Instructions: Clarifying instructions before a procedure (like fasting before a test).\n- Medication Side Effects: Reporting side effects or reactions to new medications.\n- Prescription questions or renewals',
    },
    {
      title: 'How does WelTel Help?',
      description:
        'WelTel opens an ongoing personal line of communication between you and your healthcare team so you remain connected at any time, and from anywhere.',
    },
    {
      title: 'What do I need to participate?',
      description:
        'Any type of mobile phone, including a basic cellphone with a telephone number and a cellular phone plan will work.',
    },
    {
      title: 'Do I need to download an application to use WelTel?',
      description:
        'WelTel works using native SMS texting on any mobile phone. You will not need to download an app to use WelTel.',
    },
    {
      title: "What if I don't have a phone?",
      description:
        "If you don't have a phone, someone you trust can receive and respond to your check-in messages on your behalf.",
    },
    {
      title: 'Is WelTel secure?',
      description:
        'WelTel meets Canadian and international privacy and security standards. Communications with your health providers are stored on a secure database in Canada, and are accessible by your healthcare providers only.',
    },
    {
      title: "What if I don't have cellular coverage?",
      description:
        'WelTel can also work over WiFi-based calling or using a virtual number on a WiFi-based texting app.',
    },
    {
      title: 'Do I need to have mobile data to use WelTel?',
      description:
        'WelTel operates using the telephone cellular network for SMS. A data plan is not required to communicate with your care team.',
    },
    {
      title: 'What if I accidentally unsubscribe from WelTel?',
      description:
        "If you received a message that says you are no longer enrolled in WelTel or to contact your administrator, don't worry. \n\nSimply text START to begin receiving messages again. You should receive a confirmation message confirming that you have been successfully re-registered. \n\nRemember, better heart health is just a text message away ❤️ Happy Texting. Love, the PACE team.",
    },
    {
      title: 'How do I communicate with my healthcare providers?',
      description:
        'You can directly SMS or respond to your healthcare provider when you need advice, support, or to schedule an appointment.',
    },
    {
      title: 'How do I recognize a text from my care team?',
      description:
        'Once you sign up, the WelTel SMS messages will come to your phone through an approved texting number [1-647-955-1976](tel:16479551976) that has been assigned to PACE Cardiology.\n\nPlease add this number in your contact list as PACE Cardiology. This way you will recognize the number when PACE sends an SMS communication.\n\nPlease note that you cannot call this number and must be enrolled in the program to be able to text to PACE using this number.',
    },
    {
      title: 'Who can help me get started on WelTel?',
      description:
        'For current PACE patients interested in signing up for WelTel communications, please [click on this link](https://ca-on-cardiology-pace1.weltelhealth.net/enroll?clinicId=32) or inquire with a PACE staff member to get started.',
    },
    {
      title: 'Where can I learn more about WelTel?',
      description:
        'Visit [www.weltelhealth.com](https://www.weltelhealth.com/) for more information.',
    },
  ],
};

export default function ArrhythmiaCare() {
  return (
    <Layout>
      <Seo
        title="WelTel"
        description="Together with Southlake Regional Cardiac Care Program, we've cared for patients with arrhythmias for decades."
      />
      <Hero title="WelTel Secure Messaging" banner={Banner} />
      <div className="pb-24 md:pb-52">
        <TwoCol
          title={content.cardiac_two_col?.title}
          description={content.cardiac_two_col?.description}
          image={content.cardiac_two_col.image}
        />
      </div>
      <CtaFluid
        // imgSrc, title, subTitle, btnName, btnLink, bgColor
        imgSrc={content.cta_fluid.image}
        title={content.cta_fluid?.title}
        subTitle={content.cta_fluid?.sub_title}
        btnName={content.cta_fluid?.btn_name}
        btnLink={content.cta_fluid?.btn_link_to}
        bgColor="#1D3557"
        isInternalLink={false}
      />
      <div className=" bg-gray-100 py-52 flex flex-col">
        <AccordionTitle
          title={content.accordion_title?.title}
          subTitle={content.accordion_title?.subtitle}
        >
          <Accordion
            data={content?.faq_acc}
            itemButtonClass="accordion__button__custom accordion__button"
          />
        </AccordionTitle>
      </div>
      <div className="py-52">
        <TwoCol
          title={content.cardiac_img_text?.title}
          description={content.cardiac_img_text?.description}
          image={content.cardiac_img_text.image}
          link={content.cardiac_img_text?.button_title}
          href={content.cardiac_img_text?.button_link}
          newTab
          reverse
        />
      </div>
      <Follow />
    </Layout>
  );
}
