import { divide } from 'lodash';
import React from 'react';
import { Container } from 'react-grid-system';
import ReactMarkdown from 'react-markdown';
function AccordionTitle({ title, children, subTitle }) {
  return (
    <Container className="w-full-patch">
      <h2 className="text-center mx-auto">
        <ReactMarkdown
          children={title}
          components={{
            p: React.Fragment,
          }}
        />
      </h2>
      {subTitle ? (
        <p className="max-w-7xl mx-auto my-0 pt-10 pb-20 text-center">
          {subTitle}
        </p>
      ) : (
        <div className="mb-8" />
      )}
      {children}
    </Container>
  );
}

export default AccordionTitle;
